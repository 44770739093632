<script lang="ts">
  import type { IWorkspaceUser } from '@/interface/IWorkspaceUser';
  import { t } from '@/locales/i18n';

  export let workspaceUsers: IWorkspaceUser[] = [];
  export let handleInviteSlackUser: (
    name: string,
    lastName: string,
    email: string,
  ) => void;
</script>

<div class="w-full h-64">
  <table class="w-full text-sm text-left text-gray-500 border-gray-500 mt-2">
    <thead class="text-xs text-gray-700 uppercase bg-gray-200">
      <tr>
        <th scope="col" class="px-6 py-3"
          >{$t('dashboard.users.invite-users.table-name')}</th
        >
        <th scope="col" class="px-6 py-3"
          >{$t('dashboard.users.invite-users.table-email')}</th
        >
        <th scope="col" class="px-6 py-3 text-end"
          >{$t('dashboard.users.invite-users.table-options')}</th
        >
      </tr>
    </thead>
  </table>
  <div class="overflow-y-auto h-48">
    <table class="w-full text-sm text-left text-gray-500 border-gray-500 mt-2">
      <tbody>
        {#if workspaceUsers.length > 0}
          {#each workspaceUsers as user, index}
            <tr class="bg-white border-b">
              <td class="px-6 py-4" data-cy="slack-user-fullname-{index + 1}"
                >{user.name} {user.lastName}</td
              >
              <td class="px-6 py-4" data-cy="slack-user-email-{index + 1}"
                >{user.email}</td
              >
              <td class="px-6 py-4 flex justify-center items-center">
                <button
                  class="border border-purple-800 rounded-full p-1 hover:bg-purple-800 hover:text-white transition-colors"
                  data-cy="invite-slack-user-button-{index + 1}"
                  on:click={() =>
                    handleInviteSlackUser(
                      user.name,
                      user.lastName || '_',
                      user.email,
                    )}
                >
                  +
                </button>
              </td>
            </tr>
          {/each}
        {:else}
          <tr>
            <td
              class="px-6 py-4 text-center"
              data-cy="invite-users-table-not-found-message"
              >{$t('dashboard.users.invite-users.table-no-users')}</td
            >
          </tr>
        {/if}
      </tbody>
    </table>
  </div>
</div>
