<script lang="ts">
  import LandingPageInfoElement from '@/assets/svg/LandingPageInfoElement.svelte';
  import Rizz from '@/assets/svg/Rizz.svelte';
  import AuthGuard from '@/lib/components/auth-guard/AuthGuard.svelte';
  import ExperienceCard from '@/lib/components/cards/ExperienceCard.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import { getLandingExperienceCards } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';
  import { navigate } from 'svelte-navigator';

  let isLogged = $userStore !== null;
</script>

<svelte:head>
  <title>Together</title>
</svelte:head>

<AuthGuard
  authCheck={() => !isLogged}
  onReject={() => navigate('/dashboard')}
/>
<Page>
  <div class="grow relative h-screen w-full mb-4">
    <Rizz />
    <div class="absolute inset-0 flex items-center justify-center z-10">
      <div class="text-center">
        <h1
          class="text-4xl sm:text-6xl md:text-8xl mb-6 font-bogart-bold text-primary"
          data-cy="landing-page-title"
        >
          {@html $t('home.title')}
        </h1>
        <p
          class="text-lg md:text-xl font-baloo mb-12"
          data-cy="landing-page-description"
        >
          {@html $t('home.subtitle')}
        </p>
        <a
          class="bg-primary text-white px-4 py-2 rounded hover:bg-primary/50 transition ease-in duration-200"
          href="/signup"
          data-cy="landing-page-request-trial-cta-hero"
          >{@html $t('home.cta-button')}</a
        >
      </div>
    </div>
  </div>
  <div
    class="flex flex-col xl:flex-row h-full mx-4 lg:mx-40 2xl:mx-24 my-4 border-2 border-light-grey rounded-lg mb-24"
  >
    <div class="xl:bg-[#e1e3ff] xl:items-center xl:justify-center flex">
      <LandingPageInfoElement
        styles="object-cover xl:h-[18rem] 2xl:h-[30rem]"
      />
    </div>
    <div
      id="info-section"
      class="flex-1 flex flex-col justify-between px-4 py-10 md:px-12 xl:px-20 2xl:max-w-fit"
    >
      <div class="flex flex-col gap-5 xl:items-center">
        <h2
          class="text-3xl md:text-4xl text-center xl:text-start w-full font-bogart font-bold text-primary"
        >
          {@html $t('home.info-title')}
        </h2>
        <p class="text-base md:text-base leading-snug font-baloo text-justify">
          {@html $t('home.info-text-1')}
        </p>
        <h3 class="font-bold text-center xl:text-start w-full mt-4">
          {@html $t('home.info-subtitle')}
        </h3>
        <p class="text-base md:text-base leading-snug font-baloo text-justify">
          {@html $t('home.info-text-2')}
        </p>
      </div>
      <div class="flex justify-center xl:justify-start pt-16 md:pt-4">
        <a
          class="bg-primary text-white px-4 py-2 rounded hover:bg-primary/50 transition ease-in duration-200"
          href="/signup"
          data-cy="landing-page-request-trial-cta">{$t('home.cta-button')}</a
        >
      </div>
    </div>
  </div>
  <div class="flex h-full my-4 px-0 mb-16 w-full">
    <div
      id="catalogue-section"
      class="flex flex-col gap-5 justify-between w-full mx-4 lg:mx-40 items-center"
    >
      <div
        class="flex flex-col md:flex-row justify-between w-full items-center mb-4"
      >
        <h3 class="font-bogart font-bold text-primary text-3xl md:text-4xl">
          {@html $t('home.catalogue-title')}
        </h3>
        <p class="text-xs md:text-sm font-baloo">
          {@html $t('home.catalogue-subtitle')}
        </p>
      </div>
      <div
        class="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 xl:gap-20 justify-items-center"
      >
        {#await getLandingExperienceCards()}
          <div class="w-full col-span-3">
            <Loading loadingSize="w-48" />
          </div>
        {:then response}
          {#each response.experiences as experience}
            <ExperienceCard experience={experience} isLandingPageCard />
          {/each}
        {/await}
      </div>
    </div>
  </div>
  <div class="flex h-full my-4 px-0 mb-16 w-full justify-center">
    <div
      id="about-us-section"
      class="flex-1 flex flex-col justify-between mx-4 px-4 pb-10 pt-16 md:px-12 xl:px-52 2xl:max-w-[110rem] border-2 border-light-grey rounded-lg"
    >
      <div class="flex flex-col gap-5 items-center">
        <h2
          class="text-3xl md:text-4xl text-center xl:text-start w-full font-bogart font-bold text-primary"
        >
          {@html $t('home.aboutus-title')}
        </h2>
        <p class="text-justify w-full">
          {@html $t('home.aboutus-text-1')}
        </p>
        <p class="text-justify w-full">
          {@html $t('home.aboutus-text-2')}
        </p>
        <p class="text-justify w-full">
          {@html $t('home.aboutus-text-3')}
        </p>
        <ul class="list-disc ps-4 w-full">
          <li>{@html $t('home.aboutus-list-1')}</li>
          <li>{@html $t('home.aboutus-list-2')}</li>
          <li>{@html $t('home.aboutus-list-3')}</li>
        </ul>
        <div class="flex justify-center md:justify-start pt-16 md:pt-4">
          <a
            class="bg-primary text-white px-4 py-2 rounded hover:bg-primary/50 transition ease-in duration-200"
            href="/signup"
            data-cy="landing-page-request-trial-cta-about-us"
            >{@html $t('home.cta-button')}</a
          >
        </div>
      </div>
    </div>
  </div>
</Page>
