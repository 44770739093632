<script lang="ts">
  import { t } from '@/locales/i18n';
  import { createEventDispatcher } from 'svelte';

  export let searchByOptions: string[] = [];
  let searchBy: string;
  let searchQuery = '';

  const dispatch = createEventDispatcher();

  const handleSearch = () => {
    dispatch('search', { searchBy, searchQuery });
  };
</script>

<div class="w-full relative flex justify-start items-center mb-2 mt-1">
  <select
    class="mr-2 p-2 border rounded capitalize"
    data-cy="search-by-selector"
    bind:value={searchBy}
  >
    {#each searchByOptions as option}
      <option
        value={option}
        class="capitalize"
        data-cy="search-by-option-{option}"
      >
        {option === 'name' ? $t('search.by-name') : $t('search.by-email')}
      </option>
    {/each}
  </select>
  <div class="relative w-full">
    <input
      type="text"
      placeholder={$t('search.placeholder')}
      class="w-full p-2 pl-10 text-sm text-gray-700 rounded-md ring-1 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600"
      data-cy="search-input"
      bind:value={searchQuery}
      on:input={handleSearch}
    />
    <svg
      class="w-5 h-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  </div>
</div>
