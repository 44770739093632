<script lang="ts">
  import type { IExperience } from '@/interface/IExperience';
  import FileDownloadIcon from '@/lib/components/icons/FileDownloadIcon.svelte';
  import UserIcon from '@/lib/components/icons/UserIcon.svelte';
  import { t } from '@/locales/i18n';

  export let experience: IExperience;
</script>

<div class="w-[40rem] h-fit flex flex-col gap-10">
  <img
    class="w-full h-[24rem] object-center object-cover rounded-md"
    src={experience.imageUrl}
    alt={experience.name}
  />
  <div class="flex flex-col gap-2">
    <h2 class="font-bold text-primary">{experience.name}</h2>
    <p class="text-grey text-sm">{experience.description}</p>
    <div class="flex flex-row items-center gap-4 pt-2">
      <div class="flex flex-row items-center gap-2">
        <UserIcon
          style="bg-primary w-8 h-8 p-1 rounded-full bg-primary fill-white"
        /><span class="text-primary font-semibold"
          >{$t('dashboard.experiences.details.min-participants')}</span
        >
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-2">
    {#if experience.documentUrl}
      <a
        data-cy="download-experience-document"
        target="_blank"
        href={experience.documentUrl}
        class="text-primary font-semibold flex flex-row items-center gap-2"
        ><span class="text-primary text-sm"
          >{$t('dashboard.experiences.details.res-download')}</span
        ><FileDownloadIcon style="w-5 h-5 fill-primary" />
      </a>
    {/if}
    <h2 class="font-bold text-dark-grey">
      {$t('dashboard.experiences.details.instructions-title')}
    </h2>

    <p class="text-grey text-sm">{experience.instructions}</p>
  </div>
</div>
