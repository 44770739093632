<script lang="ts">
  import StarIcon from '@/assets/svg/StarIcon.svelte';
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import { ToastNotification } from '@/lib/services/toast';
  import { submitFeedback } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-navigator';

  import { userStore } from '../../store/userStore';
  import LoginModal from './LoginModal.svelte';

  export let id: string | undefined;

  let rating = 3;
  let comment = '';
  let loading = false;

  let ratingOptions = [1, 2, 3, 4, 5] as const;

  onMount(() => {
    if (!$userStore) {
      openModal(LoginModal, { allowCloseClick: false });
    }
  });

  const handleSubmit = async () => {
    try {
      loading = true;
      await submitFeedback(+id!, { rating, comment });
      ToastNotification.success($t('dashboard.sessions.feedback-form-success'));
      navigate('/');
    } catch (error) {
      ToastNotification.error($t('dashboard.sessions.feedback-form-error'));
    } finally {
      loading = false;
    }
  };
</script>

<div
  class="flex flex-col w-full justify-center items-center h-screen bg-slate-100"
>
  <div class="flex flex-col gap-4 bg-white p-6 rounded-md">
    <h1 class="font-bold text-xl text-dark-grey">
      {$t('dashboard.sessions.feedback-form-title')}
    </h1>

    <div class="flex flex-col">
      <div>
        <span class="text-gray-900 text-sm font-semibold"
          >{$t('dashboard.sessions.feedback-form-rating')}</span
        >
        <span class="">{rating}</span>
      </div>
      <div class="flex flex-row items-center gap-2">
        {#each ratingOptions as option}
          <StarIcon
            testId={`feedback-star-${option}`}
            style={`hover:cursor-pointer hover:fill-blue-500 ${
              option <= rating ? 'fill-primary' : 'fill-grey'
            } w-8 h-8`}
            on:click={() => (rating = option)}
          />
        {/each}
      </div>
    </div>

    <Input
      name="comment"
      bind:value={comment}
      type="textarea"
      label={$t('dashboard.sessions.feedback-form-comment-label')}
      placeholder={$t('dashboard.sessions.feedback-form-comment-placeholder')}
    />
    {#if loading}
      <Loading loadingSize="h-6 w-6" />
    {:else}
      <Button testId="feedback-submit-button" on:click={handleSubmit}
        >{$t('dashboard.sessions.feedback-form-submit')}</Button
      >
    {/if}
  </div>
</div>
