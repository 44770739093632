<script lang="ts">
  import { UserRole } from '@/interface/IUser';
  import AuthGuard from '@/lib/components/auth-guard/AuthGuard.svelte';
  import DashboardSidebar from '@/lib/components/sidebar/DashboardSidebar.svelte';
  import { userStore } from '@/store/userStore';
  import { Route } from 'svelte-navigator';

  import DashboardExperiences from '../dashboard/experiences/DashboardExperiences.svelte';
  import ExperienceView from '../dashboard/experiences/ExperienceView.svelte';
  import DashboardSessions from '../dashboard/sessions/DashboardSessions.svelte';
  import DashboardUsers from '../dashboard/users/DashboardUsers.svelte';
  import MiniGames from '../mini-games/MiniGames.svelte';

  let isAdmin: boolean =
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.SUPER_ADMIN;
  let isHost: boolean = $userStore?.role === UserRole.HOST;
  let isUser: boolean = $userStore?.role === UserRole.USER;
</script>

<AuthGuard authCheck={() => isAdmin || isHost || isUser} />
<div class="w-full h-screen grow flex flex-row">
  <DashboardSidebar />
  <div class="w-full h-screen overflow-auto">
    <Route
      default
      path="/"
      component={isUser
        ? DashboardSessions
        : isAdmin
        ? DashboardUsers
        : DashboardExperiences}
    />
    <Route path="/users" component={DashboardUsers} />
    <Route path="/experiences" component={DashboardExperiences} />
    <Route path="/experiences/:id" component={ExperienceView} />
    <Route path="/sessions" component={DashboardSessions} />
    <Route primary path="/mini-games" component={MiniGames} />
  </div>
</div>
