<script lang="ts">
  import LandingPageInfoElement from '@/assets/svg/LandingPageInfoElement.svelte';
  import Rizz2 from '@/assets/svg/Rizz2.svelte';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import Button from '@/lib/components/button/Button.svelte';
  import NewLoginForm from '@/lib/components/login-form/LoginForm.svelte';
  import { t } from '@/locales/i18n';
  import { navigate } from 'svelte-navigator';
</script>

<main class="flex flex-row w-full justify-center items-center h-screen">
  <section
    class="w-[90rem] hidden h-full lg:flex flex-col justify-center items-center overflow-hidden bg-[#E0E3FF] pt-6"
  >
    <div class="w-full h-60 flex flex-col gap-6 items-center justify-center">
      <TogetherLogo className="h-24" />
      <h1 class="text-3xl font-semibold text-dark-grey">
        Bring remote teams closer
      </h1>
    </div>

    <LandingPageInfoElement styles="h-[50rem] " />
  </section>
  <section
    class="w-full h-full flex flex-col justify-center items-center overflow-hidden relative"
  >
    <Rizz2 styles="absolute w-[80rem] -right-20 -z-10" />
    <NewLoginForm />
    <div class="z-20 mt-10">
      <Button
        on:click={() => navigate('/')}
        rounded="rounded-md"
        style="outline"><span>{@html $t('login.home-button')}</span></Button
      >
    </div>
  </section>
</main>
