<script lang="ts">
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { confirmNewPassword, passwordRecovery } from '@/lib/services/cognito';
  import { ToastNotification } from '@/lib/services/toast';
  import { navigate } from 'svelte-navigator';

  import { confirmPasswordSchema } from './confirmPasswordValidation.schema';

  let email = '';
  let code = '';
  let newPassword = '';
  let repeatPassword = '';
  let errors: {
    newPassword: string | undefined;
    repeatPassword: string | undefined;
  } = {
    newPassword: undefined,
    repeatPassword: undefined,
  };
  let codeSent = false;
  let loading = false;

  const validateFields = () => {
    const validationErrors = confirmPasswordSchema.validate(
      { newPassword, repeatPassword },
      { abortEarly: false },
    );

    errors = {
      newPassword: validationErrors.error?.details.find(
        (error) => error.context?.key === 'newPassword',
      )?.message,
      repeatPassword: validationErrors.error?.details.find(
        (error) => error.context?.key === 'repeatPassword',
      )?.message,
    };
    if (errors.newPassword || errors.repeatPassword) return false;
    return true;
  };

  const handleCodeSent = async () => {
    try {
      loading = true;
      await passwordRecovery(email);
      codeSent = true;
      ToastNotification.success('Check your email for the code');
    } catch (error) {
      ToastNotification.error('Error recovering password, please try again');
    } finally {
      loading = false;
    }
  };
  const handleNewPassword = async () => {
    if (!validateFields()) return;

    try {
      loading = true;
      confirmNewPassword(newPassword, code, email);
      ToastNotification.success('Password updated successfully');
      navigate('/login', { replace: true });
    } catch (error) {
      ToastNotification.error('Error recovering password, please try again');
    } finally {
      loading = false;
    }
  };

  const handleSubmit = async () => {
    if (!codeSent) {
      await handleCodeSent();
    } else {
      await handleNewPassword();
    }
  };
</script>

<div class="w-full h-full flex flex-col items-center justify-center grow">
  <div
    class="p-6 gap-6 h-fit w-96 flex flex-col justify-center bg-white rounded-lg border border-light-grey"
  >
    <h1 data-cy="login-modal-title" class="text-3xl text-primary">
      Password recovery
    </h1>
    <Input
      bind:value={email}
      label="Email"
      name="email"
      type="email"
      testId="email-input"
    />
    {#if codeSent}
      <Input
        bind:value={newPassword}
        label="New Password"
        name="new-password"
        type="password"
        on:input={validateFields}
        onChangeValue={validateFields}
        error={errors.newPassword}
      />
      <Input
        bind:value={repeatPassword}
        label="Repeat Password"
        name="repeat-password"
        type="password"
        on:input={validateFields}
        onChangeValue={validateFields}
        error={errors.repeatPassword}
      />
      <Input bind:value={code} label="Code" name="code" type="number" />
    {/if}
    {#if loading}
      <Loading loadingSize="h-6 w-6" />
    {:else}
      <Button
        testId="password-recovery-submit"
        style="fill"
        on:click={handleSubmit}
        ><span>{codeSent ? 'Reset password' : 'Send code'}</span></Button
      >
    {/if}
  </div>
</div>
