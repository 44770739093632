<script>
  import { userStore } from '@/store/userStore';
  import UserIcon from '../icons/UserIcon.svelte';
  import { t } from '@/locales/i18n';
</script>

<div class="w-fit flex flex-row justify-center items-center gap-2">
  <UserIcon style="text-grey w-12 h-12" />
  <div class="w-fit flex flex-col">
    <span>{$t('dashboard.sidebar.welcome')}</span>
    <span class="text-sm font-bold"
      >{$userStore?.name} {$userStore?.lastName}</span
    >
  </div>
</div>
