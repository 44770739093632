<script lang="ts">
  export let label = '';
  export let name = '';
  export let testId = '';
  export let value: string | number = '';
  export let placeholder = '';
  export let styleClass = '';
  export let error = '';
  export let type = 'text';
  export let disabled = false;
  export let rows: number | undefined = undefined;
  export let max: number | undefined = undefined;
  export let min: number | undefined = undefined;
  export let minDate: string | undefined = undefined;
  export let onChangeValue: (() => void) | undefined = undefined;
</script>

<div class={styleClass}>
  {#if label}
    <div class="mb-1">
      <label class="text-sm w-full font-medium text-gray-900" for={name}
        ><span>{label}</span></label
      >
    </div>
  {/if}
  {#if type === 'password'}
    <div class="w-full flex flex-row items-center justify-center relative">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change={() => onChangeValue && onChangeValue()}
        on:input
        class="w-full focus:outline-none rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'text'}
    <div class="w-full flex flex-row items-center justify-center relative">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change={() => onChangeValue && onChangeValue()}
        on:input
        class="w-full focus:outline-none rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'email'}
    <div class="w-full flex flex-row items-center justify-center relative">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change={() => onChangeValue && onChangeValue()}
        on:input
        class="w-full focus:outline-none rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'number'}
    <div class="w-full flex flex-row items-center justify-center relative">
      <input
        id={name}
        data-cy={testId}
        on:change={() => onChangeValue && onChangeValue()}
        on:input
        class="w-full focus:outline-none rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'textarea'}
    <textarea
      id={name}
      data-cy={testId}
      name={name}
      disabled={disabled}
      bind:value={value}
      on:change={() => onChangeValue && onChangeValue()}
      on:input
      placeholder={placeholder}
      class="w-full focus:outline-none rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
      rows={rows}
    />
  {/if}
  {#if type === 'date'}
    <div class="w-full flex flex-row items-center justify-center relative">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change={() => onChangeValue && onChangeValue()}
        on:input
        class="w-full focus:outline-none rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={minDate}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {/if}
  {#if error}
    <span
      data-cy={`${name}-error`}
      class="text-xs text-red-600 font-medium w-full">{error}</span
    >
  {/if}
</div>
