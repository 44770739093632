<script lang="ts">
  import UserIcon from '../../../assets/svg/UserIcon.svelte';
  import type { IPlayer } from './IPlayer';

  export let players: IPlayer[] = [];
  export let titleSize = 'text-2xl';
  export let titleColor = 'text-white';
  export let listTextSize = 'text-base';
  export let title = 'Waiting for players...';
</script>

<div class="flex flex-col justify-center items-center">
  <div
    class={`flex flex-wrap justify-center items-center bg-primary p-2 border border-gray-500 w-full ${
      players.length > 0 ? 'rounded-t-md' : 'rounded-md'
    }`}
  >
    <h3 class={`font-semibold ${titleSize} ${titleColor} text-center`}>
      {title}
    </h3>
  </div>
  {#if players.length > 0}
    <div
      class="bg-primary p-2 border border-gray-500 rounded-m rounded-b-md w-full"
    >
      <ul class="flex flex-col items-center text-white">
        {#each players as player}
          <li class={`flex flex-row items-center gap-1 ${listTextSize}`}>
            <UserIcon />
            <span class="inline-block align-middle">{player.name}</span>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
</div>
