<script lang="ts">
  import type { IUser } from '@/interface/IUser';

  export let users: IUser[];
  export let selectedUsers: Set<IUser>;
  export let onSelect: (user: IUser) => void;
</script>

<ul
  class="h-64 flex flex-col py-2 px-4 rounded-lg gap-2 border border-light-grey overflow-auto"
>
  {#each users as user}
    <li class="w-full flex flex-row items-center">
      <label class="flex items-center gap-2 w-[30rem]">
        <input
          data-cy="invite-user-checkbox-{user.id}"
          class="accent-primary"
          type="checkbox"
          checked={selectedUsers.has(user)}
          on:change={() => onSelect(user)}
        />
        <span class="font-semibold">{user.name} {user.lastName}</span>
      </label>
      <div class="w-full flex flex-row justify-between items-center gap-4">
        <span>{user.email}</span>
        <span>{user.role}</span>
      </div>
    </li>
  {/each}
</ul>
