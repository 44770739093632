<script lang="ts">
  import IntegrationsCardImg1 from '@/assets/img/integrations-card-1.png';
  import IntegrationsCardImg2 from '@/assets/img/integrations-card-2.png';
  import Rizz from '@/assets/svg/Rizz.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';
</script>

<Page>
  <div class="grow relative h-screen w-full mb-4 my-4 md:mt-12 xl:mt-24">
    <Rizz />
    <div
      class="flex flex-col gap-4 text-center h-fit z-10 absolute mx-8 md:mx-32 xl:mx-60"
    >
      <p
        class="font-baloo text-grey text-lg mb-8"
        data-cy="integrations-info-tag"
      >
        {@html $t('integrations.infotag')}
      </p>
      <h1
        class="font-bogart font-bold text-primary text-3xl md:text-4xl xl:text-7xl mb-8"
        data-cy="integrations-info-title"
      >
        {@html $t('integrations.title')}
      </h1>
      <p
        class="text-lg md:text-xl xl:text-3xl"
        data-cy="integrations-info-description"
      >
        {@html $t('integrations.description')}
      </p>
      <div class="flex justify-center mt-28">
        <a
          class="bg-primary text-white px-4 py-2 rounded hover:bg-primary/50 transition ease-in duration-200 relative"
          href="#info-section"
        >
          {@html $t('integrations.button')}
        </a>
      </div>
    </div>
  </div>
  <div id="info-section">
    <div
      class="flex flex-col xl:flex-row h-full mx-4 lg:mx-40 2xl:mx-24 my-4 border-2 border-light-grey rounded-lg mb-24"
    >
      <div class="xl:bg-[#e1e3ff] xl:items-center xl:justify-center flex">
        <img
          src={IntegrationsCardImg1}
          class="object-cover xl:h-[18rem] 2xl:h-[20rem]"
          alt="Together Integrations"
        />
      </div>
      <div
        class="flex-1 flex flex-col justify-between px-4 py-10 md:px-12 xl:px-20 2xl:max-w-fit"
      >
        <div class="flex flex-col gap-5 xl:items-center">
          <h2
            class="text-3xl md:text-4xl text-center xl:text-start w-full font-bogart font-bold text-primary"
          >
            {@html $t('integrations.info-title-1')}
          </h2>
          <p class="md:text-lg leading-snug font-baloo text-justify">
            {@html $t('integrations.info-text-1')}
          </p>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col xl:flex-row-reverse h-full mx-4 lg:mx-40 2xl:mx-24 my-4 border-2 border-light-grey rounded-lg mb-24"
    >
      <div class="xl:bg-[#e1e3ff] xl:items-center xl:justify-center flex">
        <img
          src={IntegrationsCardImg2}
          class="object-cover xl:h-[18rem] 2xl:h-[20rem]"
          alt="Together Integrations"
        />
      </div>
      <div
        class="flex-1 flex flex-col justify-between px-4 py-10 md:px-12 xl:px-20 2xl:max-w-fit"
      >
        <div class="flex flex-col gap-5 xl:items-center">
          <h2
            class="text-3xl md:text-4xl text-center xl:text-start w-full font-bogart font-bold text-primary"
          >
            {@html $t('integrations.info-title-2')}
          </h2>
          <p class="md:text-lg leading-snug font-baloo text-justify">
            {@html $t('integrations.info-text-2')}
          </p>
        </div>
      </div>
    </div>
  </div>
</Page>
