import type { IExperience } from '@/interface/IExperience';
import type { ISession } from '@/interface/ISession';
import type { ISessionUser } from '@/interface/ISessionUser';
import type { IUser } from '@/interface/IUser';
import { userStore } from '@/store/userStore';
import { get as getStore } from 'svelte/store';

import { get, post, put } from '../axios/api-axios';

export interface IPaginatedQuery {
  offset: number;
  limit: number;
}

export const getTenantUsers = async (query?: IPaginatedQuery) => {
  const user = getStore(userStore);
  return await get<{ users: IUser[]; count: number }>('/user', {
    params: { ...query, tenantId: user!.tenant.id },
  });
};

export const updateUser = async (
  userId: number,
  updatedUser: Partial<IUser>,
) => {
  return await put(`/user/${userId}`, updatedUser);
};

export const getExperiences = async (query?: IPaginatedQuery) => {
  return await get<{ experiences: IExperience[]; count: number }>(
    '/experiences',
    {
      params: query,
    },
  );
};

interface IExperienceFeedbackQuery extends IPaginatedQuery {
  minRating?: number;
}
export const getExperienceFeedback = async (
  experienceId: number,
  query?: IExperienceFeedbackQuery,
) => {
  return await get<{ count: number; sessionUsers: ISessionUser[] }>(
    `/experiences/${experienceId}/feedback`,
    {
      params: query,
    },
  );
};

export const getExperienceById = async (experienceId: number) => {
  return await get<IExperience>(`/experiences/${experienceId}`);
};

export const createSession = async (
  experienceId: number,
  hostId: number,
  userIds: number[],
  name: string,
  scheduledDate: string,
) => {
  return await post<ISession>(`/sessions`, {
    experienceId,
    hostId,
    userIds,
    name: name.trim(),
    scheduledDate,
  });
};

export const getSessions = async (query?: IPaginatedQuery) => {
  return await get<{ sessions: ISession[]; count: number }>('/sessions', {
    params: query,
  });
};

export const updateSession = async (
  sessionId: number,
  updatedSession: Partial<ISession>,
) => {
  return await put(`/sessions/${sessionId}`, updatedSession);
};

export const finishSession = async (sessionId: number) => {
  return await put(`/sessions/${sessionId}/finish`);
};

export const submitFeedback = async (
  sessionId: number,
  feedback: { rating: number; comment: string },
) => {
  return await put(`/sessions/${sessionId}/feedback`, feedback);
};

export const getLandingExperienceCards = async () => {
  return await get<{ experiences: IExperience[]; count: number }>(
    '/public/experiences',
  );
};
