import { derived, writable } from 'svelte/store';

import translations from './translations';

const getBrowserLanguage = () => {
  if ((window as any).Cypress) {
    return 'en';
  }

  const lang = navigator.language;
  return lang ? lang.split('-')[0] : 'en';
};
const storedLocale = localStorage.getItem('locale');

export const locale = writable(storedLocale || getBrowserLanguage());

locale.subscribe((value) => {
  localStorage.setItem('locale', value);
});

export const locales = Object.keys(translations);

function translate(
  locale: string,
  key: string,
  vars: { [key: string]: any } = {},
) {
  if (!key) throw new Error('no key provided to $t()');
  if (!locale) throw new Error(`no translation for key "${key}"`);

  let text = translations[locale]?.[key];

  if (!text) throw new Error(`no translation found for ${locale}.${key}`);

  Object.keys(vars).map((k) => {
    const regex = new RegExp(`{{${k}}}`, 'g');
    text = text?.replace(regex, vars[k]);
  });

  return text;
}

export const t = derived(
  locale,
  ($locale) =>
    (key: string, vars = {}) =>
      translate($locale, key, vars),
);