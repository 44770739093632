<script lang="ts">
  import LandingPageInfoElement from '@/assets/svg/LandingPageInfoElement.svelte';
  import Rizz2 from '@/assets/svg/Rizz2.svelte';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import Button from '@/lib/components/button/Button.svelte';
  import { t } from '@/locales/i18n';
  import { navigate } from 'svelte-navigator';

  import SignupMultiStepForm from './SignupMultiStepForm.svelte';
</script>

<main class="flex flex-row w-full justify-center items-center h-screen">
  <section
    class="w-[90rem] h-full hidden lg:flex flex-col justify-center items-center overflow-hidden bg-[#E0E3FF] pt-6"
  >
    <div class="w-full h-60 flex flex-col gap-6 items-center justify-center">
      <TogetherLogo className="h-24" />
      <h1 class="text-3xl font-semibold text-dark-grey">
        Bring remote teams closer
      </h1>
    </div>

    <LandingPageInfoElement styles="h-[50rem] " />
  </section>
  <section
    class="w-full h-full flex flex-col justify-center items-center lg:overflow-hidden relative"
  >
    <Rizz2 styles="absolute w-[88rem] -right-20" />
    <SignupMultiStepForm />

    <div class="z-20 mt-10">
      <Button
        on:click={() => navigate('/')}
        rounded="rounded-md"
        style="outline"><span>{@html $t('signup.home-button')}</span></Button
      >
    </div>
  </section>
</main>
