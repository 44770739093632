<script lang="ts">
  import Button from '@/lib/components/button/Button.svelte';
  import SubmissionForm from '@/lib/components/signup-form/SignupForm.svelte';
  import { t } from '@/locales/i18n';

  import MultiStep from '../../lib/components/multi-step/MultiStep.svelte';
  import SignupInfo from './SignupInfo.svelte';

  let steps = ['Info', 'Signup'] as const;
  let currentStep: (typeof steps)[number] = steps[0];
  let multiStepForm: MultiStep;
</script>

<div class="w-full h-[45rem] flex flex-col justify-between items-center">
  <MultiStep
    styles="w-[12rem] z-20 mb-4"
    bind:this={multiStepForm}
    bind:currentStep={currentStep}
    steps={steps}
  />
  {#if currentStep === 'Info'}
    <SignupInfo />
  {/if}
  {#if currentStep === 'Signup'}
    <SubmissionForm />
  {/if}
  <div class="flex flex-row justify-center gap-10 w-full z-20 my-4">
    <Button
      testId="multi-step-back-button"
      disabled={currentStep === 'Info'}
      rounded="rounded-md"
      on:click={() => multiStepForm?.prevStep()}
    >
      <span>{@html $t('signup.button-back')}</span>
    </Button>
    <Button
      testId="multi-step-next-button"
      disabled={currentStep === 'Signup'}
      rounded="rounded-md"
      on:click={() => multiStepForm?.nextStep()}
    >
      <span>{@html $t('signup.button-next')}</span>
    </Button>
  </div>
</div>
