<script lang="ts">
  import Dice from '@/lib/components/mini-games/dice/Dice.svelte';

  let twoDices = false;

  let dice1: Dice;
  let dice2: Dice;

  const rollDices = async () => {
    if (twoDices) {
      await Promise.all([dice1.rollDice(), dice2.rollDice()]);
      return;
    }
    await dice1.rollDice();
  };
</script>

<div class="flex flex-col justify-center items-center grow">
  <div class="flex flex-row justify-between items-center p-6 w-fit gap-24">
    <Dice bind:this={dice1} />
    {#if twoDices}
      <Dice bind:this={dice2} />
    {/if}
  </div>

  <div class="flex justify-between p-6 mx-auto w-[20%]">
    <button
      class="bg-indigo-500 mt-2 px-3 py-2 flex items-center justify-center rounded-md transition ease-in duration-200 hover:bg-indigo-700 font-bold text-white"
      on:click={rollDices}
    >
      Roll
    </button>
    <button
      class="bg-indigo-500 mt-2 px-3 py-2 flex items-center justify-center rounded-md transition ease-in duration-200 hover:bg-indigo-700 font-bold text-white"
      on:click={() => (twoDices = !twoDices)}
    >
      {twoDices ? 'One Dice' : 'Two Dices'}
    </button>
  </div>
</div>
