<script lang="ts">
  import { ToastNotification } from '@/lib/services/toast';
  import { sendSubmission } from '@/lib/services/together/onboarding.api';
  import { t } from '@/locales/i18n';
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import Button from '../button/Button.svelte';
  import Input from '../input/Input.svelte';
  import Loading from '../loading/Loading.svelte';

  const navigate = useNavigate();

  const schema = yup.object({
    'org-name': yup.string().required($t('signup.form-orgname-required')),
    name: yup.string().required($t('signup.form-name-required')),
    'last-name': yup.string().required($t('signup.form-lastname-required')),
    email: yup
      .string()
      .email($t('signup.form-email-invalid'))
      .required($t('signup.form-email-required')),
  });

  type FormData = yup.InferType<typeof schema>;

  type Field = {
    name: keyof FormData;
    label: string;
    type: string;
    placeholder: string;
  };

  const { form, errors, isSubmitting } = createForm<
    yup.InferType<typeof schema>
  >({
    initialValues: {
      'org-name': '',
      name: '',
      'last-name': '',
      email: '',
    },
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      try {
        await sendSubmission({
          ...values,
          orgName: values['org-name'],
          lastName: values['last-name'],
        });
        ToastNotification.success($t('signup.form-success'));
        navigate('/');
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          ToastNotification.error(errorMessage);
        } else {
          ToastNotification.error($t('signup.form-error'));
        }
      }
    },
  });

  const fields: Field[] = [
    {
      name: 'org-name',
      label: $t('signup.form-orgname-input-label'),
      type: 'text',
      placeholder: $t('signup.form-orgname-input-placeholder'),
    },
    {
      name: 'name',
      label: $t('signup.form-name-input-label'),
      type: 'text',
      placeholder: $t('signup.form-name-input-placeholder'),
    },
    {
      name: 'last-name',
      label: $t('signup.form-lastname-input-label'),
      type: 'text',
      placeholder: $t('signup.form-lastname-input-placeholder'),
    },
    {
      name: 'email',
      label: $t('signup.form-email-input-label'),
      type: 'email',
      placeholder: $t('signup.form-email-input-placeholder'),
    },
  ];
</script>

<form
  use:form
  class="bg-white border border-light-grey gap-6 p-14 flex flex-col justify-center items-center rounded-lg w-[27rem] xl:w-[35rem] z-20"
>
  <h2
    class="text-5xl font-bold text-primary text-center"
    data-cy="submission-form-title"
  >
    {@html $t('signup.form-title')}
  </h2>
  <p
    class="text-dark-grey text-xl text-center"
    data-cy="submission-form-subtitle"
  >
    {@html $t('signup.form-subtitle')}
  </p>
  <div class="w-full flex flex-col gap-4" data-cy="submission-form">
    {#each fields as field}
      <Input
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        testId={`${field.name}-input`}
        error={$errors[field.name] ? $errors[field.name]?.[0] : ''}
        styleClass="mt-2"
      />
    {/each}
    <Button
      testId="signup-button"
      disabled={$isSubmitting}
      submit
      rounded="rounded-md"
    >
      {#if $isSubmitting}
        <Loading loadingSize="h-6 w-6" />
      {:else}
        <span class="text-xl">{@html $t('signup.form-confirm')}</span>
      {/if}
    </Button>
  </div>
</form>
