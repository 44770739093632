<script lang="ts">
  export let steps: readonly string[] = [];
  export let currentStep = '';
  export let styles = '';
  let stepIndex = 0;

  export function nextStep() {
    if (stepIndex < steps.length - 1) {
      stepIndex += 1;
      currentStep = steps[stepIndex]!;
    }
  }

  export function prevStep() {
    if (stepIndex > 0) {
      stepIndex -= 1;
      currentStep = steps[stepIndex]!;
    }
  }
</script>

<div class={styles}>
  <div class="flex flex-row items-center shrink-0 h-fit w-full">
    {#each steps as step}
      {#if steps.indexOf(step) !== 0}
        <div
          class="w-full h-2 bg-light-grey flex flex-row justify-start items-start"
        >
          <div
            class={`w-full h-full origin-left transition-all duration-300 ease-in-out bg-primary ${
              steps.indexOf(step) <= steps.indexOf(currentStep)
                ? 'scale-x-100'
                : 'scale-x-0'
            }`}
          />
        </div>
      {/if}
      <div class="w-8 h-fit flex flex-col justify-center items-center relative">
        <span
          class={` text-xl absolute -top-6 transition-colors duration-300 ${
            steps.indexOf(step) <= steps.indexOf(currentStep)
              ? 'text-primary'
              : 'text-dark-grey'
          }`}>{step}</span
        >
        <div
          class={`w-10 h-10 rounded-full flex justify-center items-center border-[.35rem] bg-white transition-colors duration-300 ${
            steps.indexOf(step) <= steps.indexOf(currentStep)
              ? 'border-primary'
              : 'border-light-grey'
          }`}
        >
          <span class="text-dark-grey font-bold">{steps.indexOf(step) + 1}</span
          >
        </div>
      </div>
    {/each}
  </div>
</div>
