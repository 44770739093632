<script lang="ts">
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import { UserRole } from '@/interface/IUser';
  import { disconnectSocket, logout } from '@/lib/services/together/user.api';
  import { locale, locales, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';
  import { link, useNavigate } from 'svelte-navigator';

  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    await logout();
    navigate('/');
  };

  $: isAuthorized =
    $userStore?.role === UserRole.SUPER_ADMIN ||
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.HOST ||
    $userStore?.role === UserRole.USER;
</script>

<nav
  class="relative bg-white border-b border-gray-200 z-10 flex flex-row justify-between items-center py-2 md:px-40 px-12"
>
  <a href="/" use:link>
    <TogetherLogo className="h-14 w-50 relative right-3 m-1" />
  </a>

  <div class="justify-center items-center gap-2 hidden md:flex w-fit xl:ps-40">
    {#if isAuthorized}
      <a
        class="text-black rounded-md px-3 py-2 text-sm font-medium hover:bg-primary hover:text-white transition ease-in duration-200"
        href="/dashboard"
        use:link
        on:click={() => disconnectSocket()}
        >{@html $t('navbar.element-dashboard')}</a
      >
    {:else}
      <a
        class="text-black rounded-md px-3 py-2 text-sm font-medium hover:bg-primary hover:text-white transition ease-in duration-200"
        href="/"
        use:link
        on:click={() => disconnectSocket()}
        data-cy="navbar-home-button">{@html $t('navbar.element-home')}</a
      >
      <a
        class="text-black rounded-md px-3 py-2 text-sm font-medium hover:bg-primary hover:text-white transition ease-in duration-200"
        href="/integrations"
        use:link
        on:click={() => disconnectSocket()}
        data-cy="navbar-integrations-button"
        >{@html $t('navbar.element-integrations')}</a
      >
    {/if}
  </div>
  <div class="justify-end gap-3 flex items-center w-72">
    {#if $userStore}
      <button
        class="text-white bg-primary rounded-md px-3 py-2 text-sm font-medium hover:bg-primary/50 hover:text-black transition ease-in duration-200"
        on:click={handleLogout}>{@html $t('navbar.element-logout')}</button
      >
    {:else}
      <div class="flex gap-3 justify-center items-center">
        <a
          class="whitespace-nowrap text-black text-center w-full rounded-md px-2 py-2 text-sm font-medium hover:bg-primary hover:text-white transition ease-in duration-200"
          href="/login"
          use:link
          on:click={() => disconnectSocket()}
          >{@html $t('navbar.element-login')}</a
        >
        <a
          id="signup-button"
          class="text-white bg-primary rounded-md w-full px-2 py-2 text-sm font-medium whitespace-nowrap hover:bg-primary/70 hover:text-black transition ease-in duration-100"
          href="/signup"
        >
          {@html $t('navbar.element-signup')}
        </a>
      </div>
    {/if}
    <div class="mx-2">
      <select class="px-1" bind:value={$locale} data-cy="language-selector">
        {#each locales as l}
          <option value={l}>{l}</option>
        {/each}
      </select>
    </div>
  </div>
</nav>
