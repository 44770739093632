<script>
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import { t } from '@/locales/i18n';
</script>

<div
  class="bg-secondary-red-50 p-8 flex flex-row items-center justify-between"
  data-cy="footer-container"
>
  <TogetherLogo className="h-10 md:h-14 w-64 relative right-10" />

  <div class="flex flex-col justify-center items-center gap-4 w-fit">
    <p class="text-primary text-xs md:text-sm">
      {@html $t('footer.copyright')}
    </p>
  </div>

  <ul
    class="flex flex-col text-primary text-sm gap-1 w-64 items-end justify-center"
  >
    <li><a href="/">{@html $t('footer.home')}</a></li>
    <li><a href="/login">{@html $t('footer.login')}</a></li>
    <li><a href="/signup">{@html $t('footer.signup')}</a></li>
    <li>
      <a href="/terms" data-cy="footer-terms-link">{@html $t('footer.terms')}</a
      >
    </li>
    <li>
      <a href="/privacy" data-cy="footer-privacy-link"
        >{@html $t('footer.privacy')}</a
      >
    </li>
    <li>
      <a href="/support" data-cy="footer-support-link"
        >{@html $t('footer.support')}</a
      >
    </li>
  </ul>
</div>
