<script>
  import { t } from '@/locales/i18n';
</script>

<div
  class="bg-white border border-light-grey gap-6 p-14 flex flex-col justify-center items-center rounded-lg w-[27rem] xl:w-[35rem] z-20"
>
  <p class="text-center text-2xl text-dark-grey">
    {@html $t('signup.info-text-1')}
  </p>
  <p class="text-center text-2xl text-dark-grey">
    {@html $t('signup.info-text-2')}
  </p>
  <p class="text-center text-2xl text-dark-grey">
    {@html $t('signup.info-text-3')}
  </p>
</div>
