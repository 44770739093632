<script lang="ts">
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import { onMount } from 'svelte';
  import { Route, Router } from 'svelte-navigator';

  import '../src/app.css';
  import Loading from './lib/components/loading/Loading.svelte';
  import Modal from './lib/components/modal/Modal.svelte';
  import { refreshSession } from './lib/services/together/user.api';
  import LandingLayout from './routes/layouts/LandingLayout.svelte';
  import LoggedLayout from './routes/layouts/LoggedLayout.svelte';
  import Login from './routes/login/Login.svelte';
  import OAuthCallback from './routes/oauth/OAuthCallback.svelte';
  import SessionFeedback from './routes/session-feedback/SessionFeedback.svelte';
  import Signup from './routes/signup/Signup.svelte';

  let checkAuth = false;
  onMount(() => {
    refreshSession().finally(() => {
      checkAuth = true;
    });
  });
</script>

{#if checkAuth}
  <main class="min-h-screen max-h-fit w-full flex flex-col">
    <div class="wrap">
      <SvelteToast />
    </div>
    <Router primary={false}>
      <Route path="/*" component={LandingLayout} />
      <Route path="/dashboard/*" component={LoggedLayout} />
      <Route path="/session/:id/feedback" component={SessionFeedback} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="oauth/callback" component={OAuthCallback} />
    </Router>
    <Modal />
  </main>
{:else}
  <main
    class="min-h-screen min-w-screen flex flex-col items-center justify-center"
  >
    <Loading loadingSize="h-32 w-32" />
  </main>
{/if}

<style>
  .wrap {
    display: contents;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    --toastBackground: white;
    --toastColor: #030303;
  }
  .wrap :global(strong) {
    font-weight: 600;
  }
</style>
