<script lang="ts">
  import type { ISession } from '@/interface/ISession';
  import { UserRole } from '@/interface/IUser';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import SessionTable from '@/lib/components/tables/SessionTable.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    finishSession,
    getSessions,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let query = {
    limit: 10,
    offset: 0,
  };

  let sessions: ISession[] | null = null;
  let count = 0;

  let isUser = $userStore?.role === UserRole.USER;
  const fetchSessions = async () => {
    const { sessions: newSessions, count: newCount } = await getSessions(query);
    sessions = newSessions;
    count = newCount;
  };

  const handleFinishSession = async (session: ISession) => {
    const finishCurrentSession = async () => {
      try {
        await finishSession(session.id);
        ToastNotification.success($t('dashboard.sessions.finish-success'));
        fetchSessions();
      } catch (error) {
        ToastNotification.error($t('dashboard.sessions.finish-error'));
        console.error(error);
      }
    };

    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        text: $t('dashboard.sessions.finish-title'),
        onSubmit: () => finishCurrentSession(),
      },
    });
  };

  $: query, fetchSessions();
  const actions = isUser
    ? []
    : [
        {
          name: 'Finish session',
          onAction: handleFinishSession,
        },
      ];
</script>

<div class="w-full h-full flex flex-col">
  <div
    class="w-full h-16 flex flex-row border-b border-light-grey justify-start items-center px-6"
  >
    <h1 class="text-xl font-bold text-primary mt-1">
      {$t('dashboard.sessions.title')}
    </h1>
  </div>

  <div class="p-6">
    <div
      class="w-full flex flex-col gap-4 rounded-lg overflow-hidden bg-white border border-light-grey p-8"
    >
      {#if sessions === null}
        <div class="w-full h-80 grow flex items-center justify-center">
          <Loading loadingSize="h-32 w-32" />
        </div>
      {:else if sessions.length === 0}
        <span class="text-dark-grey"
          >{$t('dashboard.sessions.no-sessions')}</span
        >
      {:else if sessions.length !== 0}
        <SessionTable actions={actions} sessions={sessions} />
        <Paginator
          total={count}
          query={query}
          onChange={(newQuery) => {
            query = newQuery;
          }}
        />
      {/if}
    </div>
  </div>
</div>
