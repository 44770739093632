<script lang="ts">
  import type { ISessionUser } from '@/interface/ISessionUser';
  import { t } from '@/locales/i18n';

  export let sessionUser: ISessionUser;

  const { user, feedbackRating, feedbackComment } = sessionUser;
</script>

<div
  class="w-80 h-fit gap-2 flex flex-col items-start bg-white rounded-lg shadow-lg p-6"
>
  <div>
    <p class="font-bold">
      {user.name.toLowerCase()}
      {user.lastName.toLowerCase()}
    </p>
    <p data-cy="session-user-email" class="text-sm">{user.email}</p>
  </div>
  {#if feedbackRating}
    <div class="w-full flex flex-row items-center justify-between">
      <h4 class="font-bold">{$t('dashboard.sessions.card-rating')}</h4>
      <p class="font-bold">{feedbackRating}</p>
    </div>
    {#if feedbackComment}
      <div>
        <h4 class="font-bold">{$t('dashboard.sessions.card-comment')}</h4>
        <p>{feedbackComment}</p>
      </div>
    {/if}
  {:else}
    <h4 class="font-bold">{$t('dashboard.sessions.card-feedback-pending')}</h4>
  {/if}
</div>
