interface Translations {
  [locale: string]: {
    [key: string]: string;
  };
}

const translations: Translations = {
  en: {
    // Navbar
    'navbar.element-home': 'Home',
    'navbar.element-together': 'What is Together?',
    'navbar.element-catalogue': 'Catalogue',
    'navbar.element-aboutus': 'About Us',
    'navbar.element-integrations': 'Integrations',
    'navbar.element-dashboard': 'Dashboard',
    'navbar.element-logout': 'Logout',
    'navbar.element-login': 'Login',
    'navbar.element-signup': 'Sign Up',

    // Home
    'home.title': 'Bring remote<br />teams closer',
    'home.subtitle': 'Connect with your team from anywhere in the world.',
    'home.cta-button': 'Try Together!',
    'home.info-title': '¿What is Together?',
    'home.info-text-1':
      'In a world where remote work has become the norm, connection and collaboration are more important than ever. Together is a platform that offers unique experiences that strengthen teamwork and foster meaningful relationships between members, regardless of physical distance. Integrated with platforms like Miro and Slack, which teams use regularly, allows them to quickly dive into the activities and connect. With our platform, you will also cultivate a work environment where each member feels connected and engaged by reducing feelings of isolation from remote work.',
    'home.info-subtitle': 'Connect your Team Today!',
    'home.info-text-2':
      "Don't let physical distance affect your team connection. Discover how Together can take your remote collaboration to the next level. Sign up for a free demo and start building a stronger, more connected and productive team.",
    'home.catalogue-title': 'Catalogue',
    'home.catalogue-subtitle': 'Choose the one that best suits your work team.',
    'home.aboutus-title': 'About Us',
    'home.aboutus-text-1':
      "Together was born in response to Bigger Tech's need to bring closer its more than 100 employees working from all over the world.",
    'home.aboutus-text-2':
      'The experiences offered by Together are designed based on Agile and coaching principles to foster conversations, fun, and moments of reflection between team members.',
    'home.aboutus-text-3':
      'From the experience of using Together in Bigger Tech teams, we highlight the following benefits:',
    'home.aboutus-list-1': 'Greater connection between team members',
    'home.aboutus-list-2': 'A more positive and motivating work environment',
    'home.aboutus-list-3': 'Increased sense of belonging despite the distance',

    // Footer
    'footer.copyright': '© 2024 Together - All Rights Reserved',
    'footer.home': 'Home',
    'footer.login': 'Login',
    'footer.signup': 'Sign Up',
    'footer.terms': 'Terms',
    'footer.privacy': 'Privacy',
    'footer.support': 'Support',

    // Integrations
    'integrations.infotag': 'Together Integration with Slack',
    'integrations.title':
      'Spark conversations and simplify Together Experiences Organization to Bring your remote teams closer',
    'integrations.description':
      'The Together integration with Slack allows you to automatically send invitations to experiences, reminders, and creates a space to strengthen team relationships and have fun.',
    'integrations.button': 'Know more!',
    'integrations.info-title-1':
      'Connect with your Slack workspace and invite your team members quickly',
    'integrations.info-title-2':
      'Start Sessions for you team and automatically create conversation channels to get the most of the Experience',
    'integrations.info-text-1':
      'If your team is already organized and connected with Slack, you do not need to worry about asking them to join the Together platform by themselves. Our platform is designed to be easily integrated so you can connect to your workspace and invite your team members to join your organization. The integration is done in just a few clicks and will create accounts for every member of your team that you want to invite to the platform.',
    'integrations.info-text-2':
      'Together integrations with Slack is meant to make it easy for you and for you team to be ready to use. Enjoy the convenience of having Slack channels created to share and communicate with your team when a Session is scheduled. Also, your team can start sharing their opinions and expectations. We will send you notifications when needed and provide you with all that your teams need for get the most of the Experience. From reminders to updates, we will be there to help you every step of the way. You only will need to do one thing: enjoy the Experience.',

    // Support
    'support.title': 'Support',
    'support.text-1': `If you need help, have any questions, or just want to say hello, please don't hesitate to contact our dedicated support team at <a class="text-blue-600 hover:text-blue-800" href="mailto:together@togetherexperiences.com">together@togetherexperiences.com</a>. We're here to help and want to ensure you have an amazing experience with our app. We'll do our best to respond promptly and help resolve any issues you may be facing.`,
    'support.text-2': `Please read our <a href="/privacy" class="text-blue-600 hover:text-blue-800">privacy policy</a> and our <a href="/terms" class="text-blue-600 hover:text-blue-800">terms of service</a> to learn more. You can execute your rights at any moment by contacting us.`,

    // Signup
    'signup.step-1': 'Info',
    'signup.step-2': 'Sign Up',
    'signup.button-back': 'Back',
    'signup.button-next': 'Next',
    'signup.info-text-1':
      '🎉Together is integrated with Slack to streamline user onboarding and to create a Slack channel for each experience, making it easier for participants to communicate.',
    'signup.info-text-2': `⚠️To do this, the person registering on Together must have admin permissions in their company's Slack workspace.`,
    'signup.info-text-3':
      'If you have any questions please contact us at together@togetherexperiences.com',
    'signup.form-title': 'Sign Up',
    'signup.form-subtitle': `Complete the form below and let's get Together`,
    'signup.form-confirm': 'Try Together!',
    'signup.form-success':
      'Thank you for your submission. We will send your credentials once the submission is approved.',
    'signup.form-error': 'We couldn’t submit your request.',
    'signup.form-orgname-input-label': 'Your organization name',
    'signup.form-orgname-input-placeholder': 'Organization name',
    'signup.form-orgname-required': 'Organization name is required',
    'signup.form-name-required': 'Name is required',
    'signup.form-name-input-label': 'Name',
    'signup.form-name-input-placeholder': 'Name',
    'signup.form-lastname-required': 'Lastname is required',
    'signup.form-lastname-input-label': 'Lastname',
    'signup.form-lastname-input-placeholder': 'Lastname',
    'signup.form-email-input-label': 'Email',
    'signup.form-email-input-placeholder': 'Email',
    'signup.form-email-required': 'Email is required',
    'signup.form-email-invalid': 'Email is not valid',
    'signup.home-button': 'Go Home',

    // Login
    'login.title': 'Login',
    'login.home-button': 'Go Home',
    'login.form-title': 'Login',
    'login.form-subtitle': 'Connect with your team from anywhere in the world',
    'login.form-forgot-password': 'Forgot password?',
    'login.form-submit-button': 'Login',
    'login.form-no-account': "Don't have an account? ",
    'login.form-signup-link': 'Sign Up',
    'login.form-email-invalid': 'Email is not valid',
    'login.form-email-required': 'Email is required',
    'login.form-email-input-placeholder': 'Email',
    'login.form-email-input-label': 'Email',
    'login.form-password-required': 'Password is required',
    'login.form-password-input-label': 'Password',
    'login.form-password-input-placeholder': 'Password',
    'login.form-new-password-required': 'You need to create a new password',
    'login.form-success': 'Login successful!',
    'login.form-error': 'Failed to login, please try again',

    //Login - New Password
    'login.new-password-form-title':
      'You have to set a new password for your account',
    'login.new-password-form-label': 'New password',
    'login.new-password-form-placeholder': 'Enter your new password',
    'login.new-password-form-repeat-placeholder': 'Repeat your new password',
    'login.new-password-form-submit-button': 'Confirm password',
    'login.new-password-form-required': 'Password is required',
    'login.new-password-form-min': 'Password must be at least 8 characters',
    'login.new-password-form-invalid':
      'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
    'login.new-password-form-mismatch': 'Passwords must match',
    'login.new-password-form-success': 'Password updated successfully',
    'login.new-password-form-error':
      'Error updating password, please try again',

    // Dashboard - Sidebar
    'dashboard.sidebar.welcome': 'Welcome',
    'dashboard.sidebar.users': 'Users',
    'dashboard.sidebar.experiences': 'Experiences',
    'dashboard.sidebar.sessions': 'Sessions',
    'dashboard.sidebar.logout': 'Logout',

    // Dashboard - Users
    'dashboard.users.title': 'Users',
    'dashboard.users.subtitle': 'Your team',
    'dashboard.users.button': 'Add user',
    'dashboard.users.table-name': 'Name',
    'dashboard.users.table-email': 'Email',
    'dashboard.users.table-role': 'Role',
    'dashboard.users.table-actions': 'Actions',
    'dashboard.users.table-actions-assign-role': 'Assign role',
    'dashboard.users.assign-role-title': `Update {{name}}'s role`,
    'dashboard.users.assign-role-confirm': 'Confirm',
    'dashboard.users.assign-role-close': 'Close',
    'dashboard.users.assign-role-success': 'User role updated successfully!',
    'dashboard.users.assign-role-error': 'Error updating user role',
    'dashboard.users.invite-users-title': 'Invite Users',
    'dashboard.users.invite-users.description':
      'Select the users from your Slack workspace that you want to invite',
    'dashboard.users.invite-users.not-found': 'No workspace users found',
    'dashboard.users.invite-users.integration-required': `⚠ To invite users you need to install and allow permissions from Together App in your Slack workspace.<br /> ✋ Remember that you must be a <b> workspace administrator</b> in your company's Slack to proceed with the installation. <br /> 🚧 The Together app is in process of being approved by Slack team.`,
    'dashboard.users.invite-users.get-success':
      'Slack users fetched successfully!',
    'dashboard.users.invite-users.get-error': 'Error connecting with Slack',
    'dashboard.users.invite-users.invite-success': 'User invited successfully!',
    'dashboard.users.invite-users.invite-error': 'Error inviting user',
    'dashboard.users.invite-users.table-name': 'Full Name',
    'dashboard.users.invite-users.table-email': 'Email',
    'dashboard.users.invite-users.table-options': 'Options',
    'dashboard.users.invite-users.table-no-users':
      'There are no users from your workspace available to invite',

    // Dashboard - Experiences
    'dashboard.experiences.title': 'Experiences',
    'dashboard.experiences.fetch-error': 'Something went wrong: {{error}}',
    'dashboard.experiences.details.min-participants': 'Min 2 participants',
    'dashboard.experiences.details.res-download':
      'Download resource for this experience',
    'dashboard.experiences.details.instructions-title':
      'Instructions for the team',
    'dashboard.experiences.start-session-title': 'Who do you want to invite?',
    'dashboard.experiences.start-session-subtitle':
      'Select the users you want to invite',
    'dashboard.experiences.start-session-no-users':
      'No users found. Trying inviting them from your Slack workspace!',
    'dashboard.experiences.start-session-fetch-users-error':
      'Error at trying to get users.',
    'dashboard.experiences.start-session-success':
      'Session created successfully!',
    'dashboard.experiences.start-session-error': 'Error creating session',
    'dashboard.experiences.start-session-name-input-label': 'Session name',
    'dashboard.experiences.start-session-name-input-placeholder':
      'Session name',
    'dashboard.experiences.start-session-name-input-error':
      'Session name can only contain letters and numbers',
    'dashboard.experiences.start-session-date-input-label': 'Date',
    'dashboard.experiences.start-session-confirm-button': 'Confirm',
    'dashboard.experiences.start-session-section-title': 'Start session',
    'dashboard.experiences.start-session-section-subtitle':
      'You can schedule this experience by adding your team members or inviting new members',
    'dashboard.experiences.start-session-section-button': 'Start experience',
    'dashboard.experiences.feedback-title': 'Users review',
    'dashboard.experiences.no-feedback': 'No feedback yet',
    'dashboard.experiences.language-label': 'Experiences language: ',

    // Dashboard - Sessions
    'dashboard.sessions.title': 'Sessions',
    'dashboard.sessions.no-sessions': 'No sessions yet',
    'dashboard.sessions.finish-success': 'Session finished successfully!',
    'dashboard.sessions.finish-error': 'Failed to finish session',
    'dashboard.sessions.finish-title': 'Finish session',
    'dashboard.sessions.feedback-copy-success':
      'Feedback link copied to clipboard',
    'dashboard.sessions.table-name': 'Name',
    'dashboard.sessions.table-host': 'Host',
    'dashboard.sessions.table-experience': 'Experience',
    'dashboard.sessions.table-participants': 'Participants',
    'dashboard.sessions.table-date': 'Date',
    'dashboard.sessions.table-status': 'Status',
    'dashboard.sessions.table-actions': 'Actions',
    'dashboard.sessions.table-action-copy-feedback': 'Copy feedback link',
    'dashboard.sessions.table-action-send-feedback': 'Send feedback',
    'dashboard.sessions.table-action-finish': 'Finish session',
    'dashboard.sessions.table-action-board': 'Board',
    'dashboard.sessions.card-rating': 'Rating',
    'dashboard.sessions.card-comment': 'Comment',
    'dashboard.sessions.card-feedback-pending': 'Feedback pending',
    'dashboard.sessions.feedback-form-title':
      'Leave your feedback for the last session',
    'dashboard.sessions.feedback-form-rating': 'Rating:',
    'dashboard.sessions.feedback-form-comment-label': 'Comment',
    'dashboard.sessions.feedback-form-comment-placeholder': 'Optional',
    'dashboard.sessions.feedback-form-submit': 'Submit',
    'dashboard.sessions.feedback-form-success': 'Feedback submitted',
    'dashboard.sessions.feedback-form-error': 'Error submitting feedback',

    // Experience Card
    'experience.play-button': 'Play',
    'experience.view-board-button': 'View board',
    'experience.min-participants': 'Min 2 participants',

    // Misc
    'paginator.next': 'Next',
    'paginator.previous': 'Prev',
    'search.by-name': 'Name',
    'search.by-email': 'Email',
    'search.placeholder': 'Search',
    'confirmation.modal.title': 'Confirm',
    'confirmation.modal.text': 'Are you sure?',
    'confirmation.modal.error': 'Error confirming action',
    'confirmation.modal.confirm': 'Confirm',
    'confirmation.modal.close': 'Close',
  },
  es: {
    // Navbar
    'navbar.element-home': 'Inicio',
    'navbar.element-together': '¿Que es Together?',
    'navbar.element-catalogue': 'Catálogo',
    'navbar.element-aboutus': 'Nosotros',
    'navbar.element-integrations': 'Integraciones',
    'navbar.element-dashboard': 'Dashboard',
    'navbar.element-logout': 'Cerrar sesión',
    'navbar.element-login': 'Iniciar sesión',
    'navbar.element-signup': 'Registrarse',

    // Home
    'home.title': 'Bring remote<br />teams closer',
    'home.subtitle': 'Connect with your team from anywhere in the world.',
    'home.cta-button': 'Prueba Together!',
    'home.info-title': '¿Que es Together?',
    'home.info-text-1':
      'En un mundo donde el trabajo remoto se ha convertido en la norma, la conexión y la colaboración son más importantes que nunca. Together es una plataforma que ofrece experiencias únicas que fortalecen el trabajo en equipo y fomentan vínculos significativos entre los miembros, sin importar la distancia física. Al estar integrada con plataformas como Miro y Slack, que los equipos utilizan de manera habitual, les permite sumergirse rápidamente en las actividades y conectar. Con nuestra plataforma, también cultivarás un ambiente de trabajo donde cada miembro se sienta conectado y comprometido al reducir la sensación de aislamiento del trabajo remoto.',
    'home.info-subtitle': '¡Conecta tu equipo hoy!',
    'home.info-text-2':
      'No permitas que la distancia afecte la conexión de tu equipo. Descubri cómo Together puede llevar tu colaboración remota al siguiente nivel. ¡Registrate para una demostración gratuita y comenzá a construir un equipo más fuerte, conectado y productivo!',
    'home.catalogue-title': 'Catálogo',
    'home.catalogue-subtitle':
      'Elige el que mejor se ajuste a tu equipo de trabajo.',
    'home.aboutus-title': 'Nosotros',
    'home.aboutus-text-1':
      'Together nace para responder a la necesidad de Bigger Tech para que sus 100 emplados se conecten en todo el mundo.',
    'home.aboutus-text-2':
      'Las experiencias que ofrece Together están diseñadas basándose en los principios de Agile y coaching para fomentar las conversaciones, la diversión y los momentos de reflexión entre los miembros del equipo.',
    'home.aboutus-text-3':
      'De la experiencia de usar Together en los equipos de Bigger Tech, resaltamos los siguientes beneficios: ',
    'home.aboutus-list-1':
      'Mayor conectividad entre los miembros de los equipos',
    'home.aboutus-list-2': 'Un ambiente de trabajo más positivo y motivante',
    'home.aboutus-list-3':
      'Mayor sensacion de pertenencia, sin importar la distancia',

    // Footer
    'footer.copyright': '© 2024 Together - Todos los derechos reservados',
    'footer.home': 'Inicio',
    'footer.login': 'Iniciar sesión',
    'footer.signup': 'Registrarse',
    'footer.terms': 'Términos',
    'footer.privacy': 'Privacidad',
    'footer.support': 'Soporte',

    // Integrations
    'integrations.infotag': 'Integración con Slack',
    'integrations.title':
      'Conecta tus conversaciones y simplifica el organizador de experiencias para que sus equipos de trabajo se encuentren cerca',
    'integrations.description':
      'La integración con Slack te permite enviar invitaciones automáticamente, recordatorios y crea un espacio para mejorar las relaciones de equipo y tener diversión.',
    'integrations.button': '¡Conoce más!',
    'integrations.info-title-1':
      'Conecta con tu espacio de trabajo de Slack e invita a tus miembros del equipo rápidamente',
    'integrations.info-title-2':
      'Inicia Sesiones para tu equipo y crea automáticamente canales de conversación para aprovechar al máximo la Experiencia',
    'integrations.info-text-1':
      'Si tu equipo ya está organizado y conectado con Slack, no necesitas preocuparte por pedirles que se unan a la plataforma Together por sí mismos. Nuestra plataforma está diseñada para ser fácilmente integrada, así que puedes conectarte a tu espacio de trabajo y invitar a tus miembros del equipo a unirse a tu organización. La integración se realiza en solo unos pocos clics y creará cuentas para cada miembro de tu equipo que desees invitar a la plataforma.',
    'integrations.info-text-2':
      'Las integraciones de Together con Slack están diseñadas para facilitar el uso para ti y tu equipo. Disfruta de la comodidad de tener canales de Slack creados para compartir y comunicarte con tu equipo cuando se programa una Sesión. Además, tu equipo puede comenzar a compartir sus opiniones y expectativas. Te enviaremos notificaciones cuando sea necesario y te proporcionaremos todo lo que tu equipo necesita para aprovechar al máximo la Experiencia. Desde recordatorios hasta actualizaciones, estaremos allí para ayudarte en cada paso del camino. Solo necesitarás hacer una cosa: disfrutar de la Experiencia.',

    // Support
    'support.title': 'Soporte',

    // Signup
    'signup.step-1': 'Información',
    'signup.step-2': 'Registrarse',
    'signup.button-back': 'Atras',
    'signup.button-next': 'Siguiente',
    'signup.info-text-1':
      '🎉Together es integrado con Slack para simplificar la creación de canales de usuario y la creación de experiencias para que sus 100 usuarios se conecten en todo el mundo.',
    'signup.info-text-2': `⚠️Para hacerlo, la persona registrada en Together debe tener permisos de administrador en su workspace de Slack.`,
    'signup.info-text-3':
      'Si tiene alguna pregunta, por favor póngase en contacto con nosotros en together@togetherexperiences.com',
    'signup.form-title': 'Registro',
    'signup.form-subtitle': 'Complete el siguiente formulario para registrarse',
    'signup.form-confirm': '¡Confirmar!',
    'signup.form-success':
      'Gracias por tu solicitud. Te enviaremos tus credenciales una vez que la solicitud sea aprobada.',
    'signup.form-error': 'No pudimos enviar tu solicitud.',
    'signup.form-orgname-input-label': 'El nombre de tu organización',
    'signup.form-orgname-input-placeholder': 'Nombre de la organización',
    'signup.form-orgname-required':
      'El nombre de la organización es obligatorio',
    'signup.form-name-required': 'El nombre es obligatorio',
    'signup.form-name-input-label': 'Nombre',
    'signup.form-name-input-placeholder': 'Nombre',
    'signup.form-lastname-required': 'El apellido es obligatorio',
    'signup.form-lastname-input-label': 'Apellido',
    'signup.form-lastname-input-placeholder': 'Apellido',
    'signup.form-email-input-label': 'Email',
    'signup.form-email-input-placeholder': 'Email',
    'signup.form-email-required': 'El email es obligatorio',
    'signup.form-email-invalid': 'El email no es válido',
    'signup.home-button': 'Ir al inicio',

    // Login
    'login.title': 'Iniciar sesión',
    'login.home-button': 'Ir al inicio',
    'login.form-title': 'Iniciar sesión',
    'login.form-subtitle': 'Conecta con tu equipo en cualquier parte del mundo',
    'login.form-forgot-password': '¿Olvidaste tu contraseña?',
    'login.form-submit-button': 'Ingresar',
    'login.form-no-account': '¿No tienes una cuenta? ',
    'login.form-signup-link': 'Registrate',
    'login.form-email-invalid': 'El email no es válido',
    'login.form-email-required': 'El email es obligatorio',
    'login.form-email-input-placeholder': 'Email',
    'login.form-email-input-label': 'Email',
    'login.form-password-required': 'La contraseña es obligatoria',
    'login.form-password-input-label': 'Contraseña',
    'login.form-password-input-placeholder': 'Contraseña',
    'login.form-new-password-required': 'Necesita crear una nueva contraseña',
    'login.form-success': '¡Inicio de sesión exitoso!',
    'login.form-error':
      'No se pudo iniciar sesión, por favor inténtelo de nuevo',

    // Login - New Password
    'login.new-password-form-title':
      'Debe establecer una nueva contraseña para su cuenta',
    'login.new-password-form-label': 'Nueva contraseña',
    'login.new-password-form-placeholder': 'Ingrese su nueva contraseña',
    'login.new-password-form-repeat-placeholder': 'Repita su nueva contraseña',
    'login.new-password-form-submit-button': 'Confirmar contraseña',
    'login.new-password-form-required': 'La contraseña es obligatoria',
    'login.new-password-form-min':
      'La contraseña debe tener al menos 8 caracteres',
    'login.new-password-form-invalid':
      'La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula, 1 número, 1 carácter especial',
    'login.new-password-form-mismatch': 'Las contraseñas deben coincidir',
    'login.new-password-form-success': 'Contraseña actualizada con éxito',
    'login.new-password-form-error':
      'Error al actualizar la contraseña, por favor inténtelo de nuevo',

    // Dashboard - Sidebar
    'dashboard.sidebar.welcome': 'Bienvenido',
    'dashboard.sidebar.users': 'Usuarios',
    'dashboard.sidebar.experiences': 'Experiencias',
    'dashboard.sidebar.sessions': 'Sesiones',
    'dashboard.sidebar.logout': 'Salir',

    // Dashboard - Users
    'dashboard.users.title': 'Usuarios',
    'dashboard.users.subtitle': 'Tu equipo',
    'dashboard.users.button': 'Agregar usuario',
    'dashboard.users.table-name': 'Nombre',
    'dashboard.users.table-email': 'Email',
    'dashboard.users.table-role': 'Rol',
    'dashboard.users.table-actions': 'Opciones',
    'dashboard.users.table-actions-assign-role': 'Asignar rol',
    'dashboard.users.assign-role-title': 'Asignar rol a {{name}}',
    'dashboard.users.assign-role-confirm': 'Confirmar',
    'dashboard.users.assign-role-close': 'Cerrar',
    'dashboard.users.assign-role-success': 'Rol asignado con exito',
    'dashboard.users.assign-role-error': 'Error al asignar rol',
    'dashboard.users.invite-users-title': 'Invitar Usuarios',
    'dashboard.users.invite-users.description':
      'Selecciona a los usuarios de tu espacio de trabajo de Slack que deseas invitar',
    'dashboard.users.invite-users.not-found':
      'No se encontraron usuarios del espacio de trabajo',
    'dashboard.users.invite-users.integration-required': `⚠ Para invitar usuarios necesitas instalar y permitir permisos de la aplicación Together en tu espacio de trabajo de Slack.<br /> ✋ Recuerda que debes ser un <b> administrador del espacio de trabajo</b> en el Slack de tu empresa para proceder con la instalación. <br /> 🚧 La aplicación Together está en proceso de ser aprobada por el equipo de Slack.`,
    'dashboard.users.invite-users.get-success':
      '¡Usuarios de Slack obtenidos con éxito!',
    'dashboard.users.invite-users.get-error': 'Error al conectar con Slack',
    'dashboard.users.invite-users.invite-success':
      '¡Usuario invitado con éxito!',
    'dashboard.users.invite-users.invite-error': 'Error al invitar al usuario',
    'dashboard.users.invite-users.table-name': 'Nombre completo',
    'dashboard.users.invite-users.table-email': 'Email',
    'dashboard.users.invite-users.table-options': 'Opciones',
    'dashboard.users.invite-users.table-no-users':
      'No hay ningun usuario para invitar',

    // Dashboard - Experiences
    'dashboard.experiences.title': 'Experiencias',
    'dashboard.experiences.fetch-error': 'Algo salió mal: {{error}}',
    'dashboard.experiences.details.min-participants': 'Mínimo 2 participantes',
    'dashboard.experiences.details.res-download':
      'Descargar recurso para esta experiencia',
    'dashboard.experiences.details.instructions-title':
      'Instrucciones para el equipo',
    'dashboard.experiences.start-session-title': '¿A quién quieres invitar?',
    'dashboard.experiences.start-session-subtitle':
      'Selecciona los usuarios que quieres invitar',
    'dashboard.experiences.start-session-no-users':
      'No se encontraron usuarios. ¡Intenta invitarlos desde tu espacio de trabajo de Slack!',
    'dashboard.experiences.start-session-fetch-users-error':
      'Error al intentar obtener usuarios.',
    'dashboard.experiences.start-session-success':
      '¡Sesión creada exitosamente!',
    'dashboard.experiences.start-session-error': 'Error al crear la sesión',
    'dashboard.experiences.start-session-name-input-label':
      'Nombre de la sesión',
    'dashboard.experiences.start-session-name-input-placeholder':
      'Nombre de la sesión',
    'dashboard.experiences.start-session-name-input-error':
      'El nombre de la sesión solo puede contener letras y números',
    'dashboard.experiences.start-session-date-input-label': 'Fecha',
    'dashboard.experiences.start-session-confirm-button': 'Confirmar',
    'dashboard.experiences.start-session-section-title': 'Iniciar sesión',
    'dashboard.experiences.start-session-section-subtitle':
      'Puedes programar esta experiencia agregando a tus miembros del equipo o invitando a nuevos miembros',
    'dashboard.experiences.start-session-section-button': 'Iniciar experiencia',
    'dashboard.experiences.feedback-title': 'Reseñas',
    'dashboard.experiences.no-feedback': 'No hay reseñas',
    'dashboard.experiences.language-label': 'Lenguaje de las experiencias: ',

    // Experience Card
    'experience.play-button': 'Iniciar',
    'experience.view-board-button': 'Ver tablero',
    'experience.min-participants': 'Mínimo 2 participantes',

    // Dashboard - Sessions
    'dashboard.sessions.title': 'Sesiones',
    'dashboard.sessions.no-sessions': 'No hay sesiones aún',
    'dashboard.sessions.finish-success': 'Sesión finalizada con éxito',
    'dashboard.sessions.finish-error': 'Error al finalizar la sesión',
    'dashboard.sessions.finish-title': 'Finalizar sesión',
    'dashboard.sessions.feedback-copy-success': 'Link de feedback copiado',
    'dashboard.sessions.table-name': 'Nombre',
    'dashboard.sessions.table-host': 'Anfitrión',
    'dashboard.sessions.table-experience': 'Experiencia',
    'dashboard.sessions.table-participants': 'Participantes',
    'dashboard.sessions.table-date': 'Fecha',
    'dashboard.sessions.table-status': 'Estado',
    'dashboard.sessions.table-actions': 'Acciones',
    'dashboard.sessions.table-action-copy-feedback': 'Copiar link de reseña',
    'dashboard.sessions.table-action-send-feedback': 'Enviar reseña',
    'dashboard.sessions.table-action-board': 'Tablero',
    'dashboard.sessions.table-action-finish': 'Finalizar',
    'dashboard.sessions.card-rating': 'Calificación',
    'dashboard.sessions.card-comment': 'Comentario',
    'dashboard.sessions.card-feedback-pending': 'Reseña pendiente',
    'dashboard.sessions.feedback-form-title':
      'Deja tu reseña para la última sesión',
    'dashboard.sessions.feedback-form-rating': 'Calificación:',
    'dashboard.sessions.feedback-form-comment-label': 'Comentario',
    'dashboard.sessions.feedback-form-comment-placeholder': 'Opcional',
    'dashboard.sessions.feedback-form-submit': 'Enviar',
    'dashboard.sessions.feedback-form-success': 'Reseña enviada',
    'dashboard.sessions.feedback-form-error': 'Error al enviar reseña',

    // Misc
    'paginator.next': 'Siguiente',
    'paginator.previous': 'Anterior',
    'search.by-name': 'Nombre',
    'search.by-email': 'Email',
    'search.placeholder': 'Buscar',
    'confirmation.modal.title': 'Confirmar',
    'confirmation.modal.text': '¿Estas seguro?',
    'confirmation.modal.error': 'Error al confirmar',
    'confirmation.modal.confirm': 'Confirmar',
    'confirmation.modal.close': 'Cerrar',
  },
};

export default translations;
