<script lang="ts">
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { closeModal } from '@/lib/components/modal/modal.store';
  import { ToastNotification } from '@/lib/services/toast';
  import { login } from '@/lib/services/together/user.api';

  let email = '';
  let password = '';
  let loading = false;

  const handleLogin = async () => {
    try {
      loading = true;
      await login(email, password);
      ToastNotification.success('Successfully logged in');
      closeModal();
    } catch (error) {
      ToastNotification.error('Error logging in');
    } finally {
      loading = false;
    }
  };
</script>

<div class="p-6 gap-6 h-fit flex flex-col justify-center bg-white rounded-lg">
  <h1 data-cy="login-modal-title" class="text-3xl text-dark-grey">Login</h1>
  <Input bind:value={email} label="Email" name="email" type="email" />
  <Input
    bind:value={password}
    label="Password"
    name="password"
    type="password"
  />
  {#if loading}
    <Loading loadingSize="h-6 w-6" />
  {:else}
    <Button testId="login-button" style="fill" on:click={handleLogin}
      >Login</Button
    >
  {/if}
</div>
