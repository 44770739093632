<script lang="ts">
  export let text = 'menu button';
  export let selected = false;

  $: selectedStyles = selected
    ? 'text-primary border-r-4 border-r-primary'
    : 'text-dark-grey';
</script>

<button
  class={`${selectedStyles} flex flex-row w-full items-center gap-5 font-semibold px-10 py-2  hover:text-primary  transition ease-in duration-200`}
  on:click
>
  <slot />

  <span>{text}</span>
</button>
