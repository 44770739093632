<script lang="ts">
  import type { ISessionUser } from '@/interface/ISessionUser';
  import { t } from '@/locales/i18n';

  import FeedbackCard from '../../../lib/components/cards/FeedbackCard.svelte';

  export let sessionUsers: ISessionUser[];
  let page = 1;
  $: pages = Math.ceil(sessionUsers.length / 2);
</script>

<div class="w-full flex flex-col justify-center gap-4">
  <h2 class="font-bold text-dark-grey">
    {$t('dashboard.experiences.feedback-title')}
  </h2>
  {#if sessionUsers.length > 0}
    <div class="w-full flex flex-row justify-center items-center">
      <div
        style="position: relative; right: {(page - 1) * 38 - 1.5}rem"
        class={`w-full flex flex-row justify-start items-center gap-4`}
      >
        {#each sessionUsers as sessionUser}
          <FeedbackCard sessionUser={sessionUser} />
        {/each}
      </div>
    </div>
    <div class="w-full flex flex-row justify-center items-center gap-4 h-8">
      {#each new Array(pages)
        .fill(0)
        .map((_, index) => index + 1) as currentPage}
        <button
          data-cy="feedback-page-button-{currentPage}"
          on:click={() => (page = currentPage)}
          class={`shrink-0 transition ease-in-out delay-100 rounded-full ${
            page === currentPage
              ? 'bg-primary w-12 h-4'
              : 'bg-light-grey w-3 h-3'
          }`}
        />
      {/each}
    </div>
  {:else}
    <div class="w-full flex flex-col justify-center items-center gap-4">
      <p class="text-grey text-sm">{$t('dashboard.experiences.no-feedback')}</p>
    </div>
  {/if}
</div>
