<script lang="ts">
  import Button from '@/lib/components/button/Button.svelte';
  import { t } from '@/locales/i18n';
</script>

<div
  class="w-[20rem] h-fit flex flex-col gap-4 p-6 border border-light-grey rounded-md"
>
  <h4 class="font-extrabold text-primary font-bogart text-base">
    {$t('dashboard.experiences.start-session-section-title')}
  </h4>
  <p class="text-grey text-xs">
    {$t('dashboard.experiences.start-session-section-subtitle')}
  </p>
  <Button testId="start-session-button" rounded="rounded-md" on:click>
    <span> {$t('dashboard.experiences.start-session-section-button')}</span>
  </Button>
</div>
