<script lang="ts">
  import type { CardType } from '@/lib/components/mini-games/lucky-cards/@types/LuckyCards';
  import { createEventDispatcher } from 'svelte';
  import { slide } from 'svelte/transition';

  import { ToastNotification } from '../../../services/toast';

  const dispatch = createEventDispatcher();

  export let cards: CardType[] = [];
  export let showSidebar = false;

  let selectedSuit = 'classic';
  let newName = '';
  let newContent = '';

  function addCard() {
    if (
      !newName ||
      !newContent ||
      newName.length > 20 ||
      newContent.length > 20 ||
      !cards ||
      cards.length >= 16
    ) {
      return;
    }
    const newCard = {
      name: newName,
      content: newContent,
      flipped: false,
      suit: selectedSuit,
    };

    const updatedCards = [...cards, newCard];
    dispatch('updateCards', updatedCards);
    newName = '';
    newContent = '';
    ToastNotification.success('Card added successfully!', {
      duration: 2000,
    });
  }

  function updateCard(index: number, field: 'name' | 'content', value: string) {
    if (value.length > 40) {
      if (field === 'name') {
        errorUpdateName = 'Name must be less than 40 characters';
      } else {
        errorUpdateContent = 'Content must be less than 40 characters';
      }
      return;
    } else {
      errorUpdateName = '';
      errorUpdateContent = '';
    }

    const updatedCards = cards.map((card, i) =>
      i === index ? { ...card, [field]: value } : card,
    );
    dispatch('updateCards', updatedCards);
  }

  function removeCard(index: number) {
    const updatedCards = cards.filter((_, i) => i !== index);
    dispatch('updateCards', updatedCards);
    ToastNotification.success('Card removed successfully!');
  }

  function saveCards() {
    if (!cards.length) {
      ToastNotification.error('No cards to save');
      return;
    }
    localStorage.setItem('cards', JSON.stringify(cards));
    dispatch('updateCards', cards);
    ToastNotification.success('Cards saved successfully!');
  }

  function updateCardsSuit(newSuit: string) {
    selectedSuit = newSuit;

    const updatedCards = cards.map((card) => {
      card = { ...card, suit: newSuit };
      return card;
    });
    dispatch('updateCards', updatedCards);
  }

  let errorName = '';
  let errorContent = '';
  let errorUpdateName = '';
  let errorUpdateContent = '';

  $: if (newName && newName.length > 40) {
    errorName = 'Name must be less than 40 characters';
  } else {
    errorName = '';
  }

  $: if (newContent && newContent.length > 20) {
    errorContent = 'Content must be less than 20 characters';
  } else {
    errorContent = '';
  }
</script>

{#if showSidebar}
  <aside class="sidebar overflow-auto" transition:slide>
    <div class="flex flex-col">
      <h3 class="text-3xl mb-5">Cards Config</h3>
      <div class="grid grid-cols-2 justify-start gap-4 mb-5">
        <button
          on:click={() => dispatch('prepareCards')}
          disabled={!cards.length}>Prepare Cards</button
        >
        <button
          on:click={() => dispatch('revealAllCards')}
          disabled={!cards.length}>Reveal all</button
        >
        <button
          on:click={() => dispatch('removeAllCards')}
          disabled={!cards.length}>Remove all</button
        >
        <button on:click={saveCards} disabled>Save cards</button>
      </div>

      <div class="flex flex-col my-4">
        <p class="text-2xl pb-4">Suit style:</p>
        <select
          class="w-full p-2"
          bind:value={selectedSuit}
          on:change={(e) =>
            e.target && updateCardsSuit(e.currentTarget.value || '')}
        >
          <option value="classic">Classic Style</option>
          <option value="color">Color Style</option>
        </select>
      </div>

      <div class="card-inputs">
        <h3 class="text-2xl my-5">Add Card</h3>
        <input
          type="text"
          placeholder="Enter card suit (max 20 chars)"
          bind:value={newName}
        />
        {#if errorName}<p class="error error-message">{errorName}</p>{/if}
        <input
          type="text"
          placeholder="Enter card content (max 20 chars)"
          bind:value={newContent}
        />
        {#if errorContent}<p class="error error-message">{errorContent}</p>{/if}
        <button
          on:click={addCard}
          disabled={newName.length > 20 ||
            newContent.length > 20 ||
            !newName ||
            !newContent}
        >
          Add Card
        </button>
      </div>

      <h3 class="text-2xl my-5">Cards</h3>
      <div class="cards-container">
        {#each cards as card, index (index)}
          <h3 class="text-2xl my-5">Card {index + 1}</h3>
          <div class="card-inputs">
            <p>Name:</p>
            <input
              type="text"
              placeholder="Update card name"
              bind:value={card.name}
              on:input={(e) =>
                e.target &&
                updateCard(index, 'name', e.currentTarget.value || '')}
            />
            {#if index === 0 && errorUpdateName}<p class="error error-message">
                {errorUpdateName}
              </p>{/if}
            <p>Content:</p>
            <input
              type="text"
              placeholder="Update content"
              bind:value={card.content}
              on:input={(e) =>
                e.target &&
                updateCard(index, 'content', e.currentTarget.value || '')}
            />
            {#if index === 0 && errorUpdateContent}<p
                class="error error-message"
              >
                {errorUpdateContent}
              </p>{/if}
            <button on:click={() => removeCard(index)}>Remove Card</button>
          </div>
        {/each}
        {#if !cards.length}
          <p>No cards added yet</p>
        {/if}
      </div>
    </div>
  </aside>
{/if}

<style>
  input {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input:focus {
    border-color: #4a90e2;
    outline: none;
  }

  .error {
    color: red;
    font-weight: bold;
  }

  .error-message {
    margin: 4px 0;
    display: block;
  }

  button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4a90e2;
    color: white;
    cursor: pointer;
    margin-top: 8px;
  }

  button:disabled {
    background-color: #ccc;
    cursor: default;
  }

  .card-inputs {
    margin-bottom: 16px;
  }

  .cards-container {
    height: calc(100% - 40px);
  }

  .sidebar {
    padding: 20px;
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #f9f9f9;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
</style>
