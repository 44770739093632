<script lang="ts">
  export let diceFace: number;
  export let diceFaceColor = 'bg-gray-700';
</script>

{#if diceFace === 1}
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
{:else if diceFace === 2}
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
{:else if diceFace === 3}
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
{:else if diceFace === 4}
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
{:else if diceFace === 5}
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class="w-6 h-6 rounded-full" />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
{:else if diceFace === 6}
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
{:else}
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
  <div class="space-y-4 pt-1">
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
    <div class="w-6 h-6 rounded-full" />
  </div>
  <div class="space-y-4 pt-1">
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
    <div class={`w-6 h-6 ${diceFaceColor} rounded-full`} />
  </div>
{/if}
