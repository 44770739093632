<script lang="ts">
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';
</script>

<Page>
  <div class="flex justify-center">
    <h1 class="text-3xl font-bold" data-cy="support-page-title">Support</h1>
  </div>
  <div class="flex justify-center items-center mt-4">
    <div
      class="max-w-4xl mx-auto p-8 bg-white rounded-md border-gray-500 shadow-md"
    >
      <p class="text-lg text-justify mb-4">
        {@html $t('support.text-1')}
      </p>
      <p class="text-lg text-justify">
        {@html $t('support.text-2')}
      </p>
    </div>
  </div>
</Page>
