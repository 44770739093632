<script lang="ts">
  import { closeModal } from '../../lib/components/modal/modal.store';

  const close = () => {
    closeModal();
  };
</script>

<div
  on:click|stopPropagation
  on:keydown
  class="flex justify-center items-center relative flex-col rounded-lg gap-8 shadow-lg sm:w-96 w-11/12 pb-8 pt-10 bg-white"
>
  <h1 class="text-3xl font-bold">Thanks for playing</h1>
  <button
    class="bg-indigo-500 w-16 h-10 rounded-md transition ease-in duration-200 hover:bg-indigo-700 font-bold text-white"
    on:click={close}>OK</button
  >
</div>
