<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import CloseXMark from '../../../assets/svg/CircleXMark.svelte';
  import clickOutside from '../helpers/clickOutside';

  export let showModal = false;
  export let backgroundColor = 'bg-white';
  export let backgroundBorderColor = 'border-gray-200';
  export let buttonTextColor = 'text-black';
  export let buttonTextSize = 'text-md';
  export let description = 'Modal description';
  export let title = 'Modal title';
  const dispatch = createEventDispatcher();
</script>

{#if showModal}
  <div
    class="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 p-2"
  >
    <div class="relative w-auto my-6 mx-auto max-w-xl">
      <slot name="modal-content">
        <div
          class={`relative flex flex-col rounded-lg shadow-lg w-full ${backgroundColor}`}
          use:clickOutside={() => dispatch('close')}
        >
          <div
            class={`flex items-start justify-between p-4 border-b ${backgroundBorderColor} rounded-t`}
          >
            <h3 id="modal-title" class="text-2xl font-semibold capitalize">
              {title}
            </h3>
            <button
              title="close modal"
              class="p-1 ml-auto transition ease-in duration-100 hover:scale-125"
              on:click={() => dispatch('close')}
            >
              <CloseXMark color="text-black" size="28" />
            </button>
          </div>
          <div class="relative p-4 my-2">
            <slot name="main-content">
              <p id="modal-description" class="text-lg leading-relaxed">
                {description}
              </p>
            </slot>
          </div>
          <div
            class={`flex items-center justify-end p-4 border-t ${backgroundBorderColor} rounded-b`}
          >
            <button
              class={`${buttonTextColor} font-bold uppercase ${buttonTextSize} transition ease-in duration-100 hover:scale-110`}
              type="button"
              on:click={() => dispatch('close')}
            >
              Close
            </button>
          </div>
        </div>
      </slot>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
