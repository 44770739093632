<script lang="ts">
  import {
    ExperienceLanguages,
    type IExperience,
  } from '@/interface/IExperience';
  import ExperienceCard from '@/lib/components/cards/ExperienceCard.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import { getExperiences } from '@/lib/services/together/together.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';
  import { navigate } from 'svelte-navigator';

  let query = {
    limit: 6,
    offset: 0,
  };

  let experiencesPromise: Promise<{
    experiences: IExperience[];
    count: number;
  }>;

  let selectedLanguage: string =
    $locale === 'en'
      ? ExperienceLanguages.english
      : ExperienceLanguages.spanish;

  const fetchExperiences = () => {
    experiencesPromise = getExperiences(query);
  };
  $: query, fetchExperiences();

  const handleExperienceDetails = (experience: IExperience) => {
    navigate(`/dashboard/experiences/${experience.id}`);
  };

  const checkIfAllowed = (experience: IExperience) =>
    $userStore?.tenant.subscriptionPlan !== undefined &&
    $userStore.tenant.subscriptionPlan < experience.allowInPlan;

  const handleLanguageChange = (event: Event) => {
    selectedLanguage = (event.target as HTMLSelectElement).value;
  };
</script>

<div class="w-full h-full flex flex-col">
  <div
    class="w-full h-16 flex flex-row border-b border-light-grey justify-start items-center px-6"
  >
    <h1 class="text-xl font-bold text-primary mt-1">
      {$t('dashboard.experiences.title')}
    </h1>
  </div>
  <div class="w-full h-16 flex flex-row justify-start items-center px-6">
    <div class="flex flex-row items-center">
      <label for="language" class="text-sm font-medium text-gray-900 me-3"
        >{$t('dashboard.experiences.language-label')}</label
      >
      <select
        name="language"
        class="w-full h-8 pl-2 pr-8 text-sm text-gray-700 capitalize"
        data-cy="experience-language-selector"
        on:change={(e) => handleLanguageChange(e)}
      >
        {#each Object.values(ExperienceLanguages) as language}
          <option value={language} selected={language === selectedLanguage}
            >{language}</option
          >
        {/each}
      </select>
    </div>
  </div>
  <div class="w-full flex flex-col gap-2 p-6">
    {#await experiencesPromise}
      <div class="w-full h-80 grow flex items-center justify-center">
        <Loading loadingSize="h-32 w-32" />
      </div>
    {:then { experiences, count }}
      <div class="w-full flex flex-wrap gap-4">
        {#each experiences as experience}
          {#if experience.language === selectedLanguage}
            <ExperienceCard
              blockUser={checkIfAllowed(experience)}
              experience={experience}
              startSession={() => handleExperienceDetails(experience)}
            />
          {/if}
        {/each}
      </div>
      <div class="p-4">
        <Paginator
          total={count}
          query={query}
          onChange={(newQuery) => {
            query = newQuery;
          }}
        />
      </div>
    {:catch error}
      <p class="text-center" data-cy="error-message">
        {$t('dashboard.experiences.fetch-error', { error: error.message })}
      </p>
    {/await}
  </div>
</div>
