<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import { hostSocketSession } from '../../../store/socketSession';
  import type { IGamePhase } from './IGamePhases';

  export let gamePhases: IGamePhase[];
  export let hasAdminPrivileges = false;

  const dispatch = createEventDispatcher();

  const sendStatusGamePhases = (phaseName: string, isCompleted: boolean) => {
    const updatedGamePhases = gamePhases.map((phase) => {
      if (phase.name === phaseName) {
        return {
          ...phase,
          gameInProgress: true,
        };
      }
      return phase;
    });

    dispatch('statusPhasesGames', {
      gamePhases: updatedGamePhases,
      hasGameMenu: isCompleted,
      phase: phaseName,
    });
  };
</script>

<div
  class="flex flex-wrap flex-row justify-center items-center gap-2 text-center"
>
  {#each gamePhases as phase}
    <button
      title={phase.name}
      on:click={() => {
        sendStatusGamePhases(phase.name, true);
      }}
      class={`flex items-center justify-center bg-accent/80 active:bg-accent rounded-md w-[12rem] h-[8rem] xl:w-[16rem] xl:h-[12rem] ${
        phase.isCompleted ? 'bg-green-500' : ''
      }
      ${
        $hostSocketSession || hasAdminPrivileges
          ? ''
          : 'cursor-not-allowed opacity-75'
      }`}
      disabled={$hostSocketSession || hasAdminPrivileges ? false : true}
    >
      <span class="text-black font-bold text-2xl capitalize">{phase.name}</span>
    </button>
  {/each}
</div>
