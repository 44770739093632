<script lang="ts">
  import type { IUser } from '@/interface/IUser';
  import Button from '@/lib/components/button/Button.svelte';
  import PlusIcon from '@/lib/components/icons/PlusIcon.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import AssignRoleModal from '@/lib/components/modal/modals/AssignRoleModal.svelte';
  import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
  import InviteUsersModal from '@/lib/components/modal/modals/InviteUsersModal.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import UserTable from '@/lib/components/tables/UserTable.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { getTenantUsers } from '@/lib/services/together/together.api';
  import {
    deleteUser,
    resendUserCredentials,
  } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';
  import type { ComponentProps } from 'svelte';

  let query = {
    limit: 7,
    offset: 0,
  };

  let users: IUser[] = [];
  let count = 0;

  const fetchUsers = async () => {
    const { users: newUsers, count: newCount } = await getTenantUsers(query);
    users = newUsers;
    count = newCount;
  };
  $: query, fetchUsers();

  const handleAssignRole = (user: IUser) =>
    openModal(AssignRoleModal, {
      allowCloseClick: false,
      props: { user, onSubmit: fetchUsers },
    });

  const handleInviteUsers = () => {
    openModal(InviteUsersModal, {
      onClose: fetchUsers,
    });
  };

  const handleResendCredentials = async (user: IUser) => {
    const resendCredentials = async (user: IUser) => {
      try {
        await resendUserCredentials(user);
        ToastNotification.success('Credentials sent successfully!');
      } catch (error) {
        ToastNotification.error('Error sending credentials');
      }
    };

    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        text: 'Resend credentials to ' + user.email,
        onSubmit: () => resendCredentials(user),
      },
    });
  };

  const handleDeleteUser = async (user: IUser) => {
    const confirmDeleteUser = async (user: IUser) => {
      try {
        await deleteUser(user);
        fetchUsers();
        ToastNotification.success('User deleted successfully!');
      } catch (error) {
        ToastNotification.error('Error deleting user');
      }
    };

    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        title: 'Confirm',
        text:
          'Are you sure you want to delete the user with email ' + user.email,
        onSubmit: () => confirmDeleteUser(user),
      },
    });
  };

  const actions: ComponentProps<UserTable>['actions'] = [
    {
      name: $t('dashboard.users.table-actions-assign-role'),
      onAction: handleAssignRole,
      disabledCondition: (user: IUser) => user.id === $userStore!.id,
    },
    {
      name: 'Resend credentials',
      onAction: handleResendCredentials,
      disabledCondition: (user: IUser) => user.id === $userStore!.id,
    },
    {
      name: 'Delete',
      onAction: handleDeleteUser,
      disabledCondition: (user: IUser) =>
        user.id === $userStore!.id || user.sessions.length > 0,
    },
  ];
</script>

<div class="w-full h-full flex flex-col">
  <div
    class="w-full h-16 flex flex-row border-b border-light-grey justify-start items-center px-6"
  >
    <h1 class="text-xl font-bold text-primary mt-1">
      {$t('dashboard.users.title')}
    </h1>
  </div>
  <div class="p-6">
    <div
      class="w-full flex flex-col gap-4 rounded-lg bg-white border border-light-grey p-8"
    >
      <h2 class="text-dark-grey font-bold">{$t('dashboard.users.subtitle')}</h2>
      {#if users.length > 0}
        <UserTable actions={actions} users={users} />
        <Button
          testId="invite-users-button"
          on:click={handleInviteUsers}
          style="outline"
          ><PlusIcon /><span>{$t('dashboard.users.button')}</span></Button
        >
        <Paginator
          total={count}
          query={query}
          onChange={(newQuery) => {
            query = newQuery;
          }}
        />
      {:else}
        <div class="w-full h-80 grow flex items-center justify-center">
          <Loading loadingSize="h-32 w-32" />
        </div>
      {/if}
    </div>
  </div>
</div>
